import Base from '@studiometa/js-toolkit-legacy/index.umd.js';
import LegacySelect from './LegacySelect.js';
import RessourceList from './RessourceList.js';

export default class App extends Base.default {
  static config = {
    name: 'App',
    components: {
      LegacySelect,
      RessourceList,
    },
    refs: ['form', 'input', 'container', 'pagination[]'],
  };

  /**
   * Get all the current values from the <select> inside the Select component
   */
  getSelectValues(data) {
    // Add all select values to the data object
    this.$children.Select.forEach((select) => {
      const { name, value } = select.$refs.select;
      data[name] = value;
    });

    return data;
  }

  /**
   * On data-component="Select" change event
   */
  onSelectChange(event, index) {
    let data = {
      action: 'filter_ressources',
    };

    data = this.getSelectValues(data);
    this.query(data);
  }

  /**
   * on data-component="RessourceList" change
   */
  onRessourceListChange(paged) {
    let data = {
      action: 'filter_ressources',
      paged,
    };
    data = this.getSelectValues(data);
    this.query(data);
  }

  /**
   * on ref="form" submit
   */
  onFormSubmit(event) {
    event.preventDefault();

    let data = {
      action: 'filter_ressources',
      search: this.$refs.input.value,
    };
    data = this.getSelectValues(data);
    this.query(data);
  }

  /**
   * Ajax query
   * @see wp_ajaax_filter_ressources
   */
  query(data) {
    this.$refs.container.classList.add('is-loading');

    $.ajax({
      url: '/wp/wp-admin/admin-ajax.php',
      method: 'POST',
      type: 'POST',
      data,
      success: (data) => {
        if (data) {
          this.$children.RessourceList[0].$el.innerHTML = data.data;
          this.$refs.container.classList.remove('is-loading');
        }
      },
      error: (error) => {
        throw new Error(error);
      },
      complete: () => {
        this.$children.RessourceList[0].$update();
      },
    });
  }
}
