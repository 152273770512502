import Default from './default.js';
import App from '../components/App.js';

export default class Ressources extends Default {
  /**
   * Init fn
   */
  init() {
    this.app = new App(document.body);
    this.app.$mount();
  }
}
