import TweenMax from 'gsap';
import Pointer from './pointer.js';

const log = window.console.log.bind(window, 'Drag:');

export default class Drag {
  drag = {
    x: {
      ease: -1,
      min: 0,
      max: 0,
    },
    y: {
      ease: -1,
      min: 0,
      max: 0,
    },
  };

  slider = {
    currentElt: 0,
    x: {
      current: 0,
      ease: -1,
      min: 0,
      max: 0,
    },
    y: {
      current: 0,
      ease: -1,
      min: 0,
      max: 0,
    },
  };

  constructor({ debug = false, snap = false, btnSelector, itemSelector, onStart, onEnd }) {
    debug && log('constructor');
    this.debug = debug;
    this.isRunning = false;
    this.$btn = document.querySelector(btnSelector);
    this.$items = document.querySelectorAll(itemSelector);
    this.pointer = new Pointer({ debug: false });
    this.snap = snap;

    this.requestFrameID = 0;

    this.onStart = onStart || function () {};
    this.onEnd = onEnd || function () {};
  }

  init() {
    this.debug && log('init');
    this.$btn.addEventListener('mousedown', this, { passive: true });
    this.$btn.addEventListener('touchstart', this, { passive: true });

    const itemSize = this.$items[0].getBoundingClientRect().width;

    this.setSliderBounds(this.$items.length, itemSize, window.innerWidth);

    this.setDragBounds(itemSize, window.innerWidth);

    return this;
  }

  setSliderBounds(itemsLength, itemSize, viewportWidth) {
    const { slider } = this;
    this.widthSlider = 0;
    $.each(this.$items, (index, item) => {
      // this.$items.forEach((item) => {
      const style =
        item.currentStyle !== undefined ? item.currentStyle : window.getComputedStyle(item);
      this.widthSlider += item.offsetWidth + Number.parseFloat(style.marginLeft);
    });

    slider.x.max = 0;
    slider.x.min =
      (this.widthSlider - this.$items[itemsLength - 1].offsetWidth - slider.x.max) * -1;
    slider.currentElt = 0;
  }

  setDragBounds(itemWidth, viewportWidth) {
    const { drag } = this;
    drag.x.max = viewportWidth / 2;
    drag.x.min = viewportWidth / -2;
    drag.y.max = itemWidth / 2;
    drag.y.min = itemWidth / -2;
  }

  handleEvent(e) {
    try {
      this[`${e.type}Handler`].call(this, e);
    } catch (e) {
      this.debug && log(`Event of type "${e.type}" has no defined handler.`);
    }
  }

  mousedownHandler(e) {
    this.debug && log('mousedownHandler');
    document.addEventListener('mouseup', this);
    this.start();
  }

  mouseupHandler(e) {
    this.debug && log('mouseupHandler');
    this.end();
  }

  touchstartHandler(e) {
    this.debug && log('touchstartHandler');
    document.addEventListener('touchend', this);
    this.start();
  }

  touchendHandler(e) {
    this.debug && log('touchendHandler');
    this.end();
  }

  start() {
    this.pointer.init();

    if (this.onStart != null) this.onStart.call();

    // Check if update is already
    // running before calling it
    if (!this.isRunning) {
      this.isRunning = true;
      setTimeout(this.update.bind(this), 1);
    }
  }

  update() {
    const { pointer } = this;
    const { slider } = this;
    const { drag } = this;
    const ease = pointer.x.delta === 0 ? 0.25 : 0.75;

    if (!pointer.isDown && this.snap) {
      const currentX = slider.x.ease;
      const widthRef = $(this.$items).not(':not(.active)').outerWidth();

      slider.currentElt = currentX > 0 ? 0 : Math.round((currentX + widthRef * 0.4) / widthRef);

      if (Math.abs(slider.currentElt) >= this.$items.length)
        slider.currentElt = this.$items.length - 1;

      slider.x.current = slider.currentElt * widthRef;

      if (this.onEnd != null) this.onEnd.call();
    }

    if (pointer.isDown || !(drag.x.ease === pointer.x.delta && drag.y.ease === pointer.y.delta)) {
      slider.x.current += pointer.x.delta / 10;
      if (slider.x.current > slider.x.max) slider.x.current = slider.x.max;
      if (slider.x.current < slider.x.min) slider.x.current = slider.x.min;
      slider.x.ease += (slider.x.current - slider.x.ease) * 0.1;
      if (Math.abs(slider.x.current - slider.x.ease) < 0.1) slider.x.ease = slider.x.current;

      const deltaX = pointer.x.delta - drag.x.ease;
      drag.x.ease += deltaX * ease;
      if (drag.x.ease < drag.x.min) drag.x.ease = drag.x.min;
      if (drag.x.ease > drag.x.max) drag.x.ease = drag.x.max;
      if (Math.abs(deltaX) < 0.1) drag.x.ease = pointer.x.delta;

      const deltaY = pointer.y.delta - drag.y.ease;
      drag.y.ease += deltaY * ease;
      if (drag.y.ease < drag.y.min) drag.y.ease = drag.y.min;
      if (drag.y.ease > drag.y.max) drag.y.ease = drag.y.max;
      if (Math.abs(deltaY) < 0.1) drag.y.ease = pointer.y.delta;

      TweenMax.set(this.$btn, { x: `${drag.x.ease}px`, y: `${drag.y.ease}px` });
      // this.$btn.style.transform = `translate3d(${drag.x.ease}px, ${drag.y.ease}px, 0)`
      $.each(this.$items, function (index, element) {
        // this.$items.forEach(($item) => {
        TweenMax.set(element, { x: `${slider.x.ease}px` });
        // $item.style.transform = `translate3d(${slider.x.ease}px, 0, 0)`
      });

      this.requestFrameID = requestAnimationFrame(this.update.bind(this));
    } else {
      this.isRunning = false;
    }
  }

  end() {
    this.debug && log('end');
    this.pointer.destroy();

    // if( this.onEnd != null ) this.onEnd.call()
  }
}
