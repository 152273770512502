import $ from 'jquery';
import Default from './default.js';
import { inViewport } from '../utils.js';
import config from '../config.js';

export default class Home extends Default {
  constructor() {
    super();
  }

  init() {
    super.init();

    this.$listingItems = $('.js-lazy-hover');

    const $filterBtn = $('.js-filters-btn');
    const $catalogue = $('.catalogue');

    $filterBtn.on('click', function (e) {
      $catalogue.toggleClass('filters-active');
    });

    config.$window.on('scrollEnd', this.handleListingScrollEnd.bind(this));
    config.$window.trigger('scrollEnd');

    $(document).on('facetwp-refresh', this.handleListingScrollEnd.bind(this));
  }

  handleListingScrollEnd() {
    this.$listingItems = $('.js-lazy-hover');

    this.$listingItems.each((i, e) => {
      if (inViewport(e)) {
        const $el = $(e);

        if ($el.length > 0 && !$el.hasClass('is-loaded')) {
          $el.addClass('is-loaded');
          const imgSrc = $el.data('url');
          const img = new Image();

          img.addEventListener('load', () => {
            $el.append(`<img src="${imgSrc}" class="hover-image"/>`);
          });

          img.onerror = () => {
            console.log('handleListingScrollEnd:NO IMAGE FOUND FOR INDEX', i);
          };

          img.src = imgSrc;
        }
      }
    });
  }
}
