const log = window.console.log.bind(window, 'Pointer:');

export default class Pointer {
  /**
   * Set up all variables
   * @returns Object The created instance
   */
  constructor({ debug = false }) {
    debug && log('constructor');
    this.debug = debug;
    this.setDefaultPointerValue();
    this.isDown = false;
  }

  /**
   * Init the class by binding events to the document
   */
  init() {
    this.debug && log('init');
    document.addEventListener('touchstart', this, { passive: true });
    document.addEventListener('mousedown', this, { passive: true });
    return this;
  }

  /**
   * Unbind all doc events
   */
  destroy() {
    this.debug && log('destroy');
    document.removeEventListener('touchstart', this);
    document.removeEventListener('mousedown', this);
    return this;
  }

  /**
   * Get default value for the pointer object
   */
  setDefaultPointerValue(x = null, y = null) {
    this.debug && log('getDefaultPointerValue', x, y);
    (this.x = {
      start: x,
      current: x,
      delta: 0,
    }),
      (this.y = {
        start: y,
        current: y,
        delta: 0,
      });
  }

  /**
   * Event dispatcher
   * @param Object e The event's object
   */
  handleEvent(e) {
    this.debug && log('handleEvent', e.type);
    try {
      this[`${e.type}Handler`].call(this, e);
    } catch (e) {
      this.debug && log(`Event of type "${e.type}" has no defined handler.`);
    }
  }

  /**
   * Start to move, set all values
   * and start the loop
   */
  start(x, y) {
    this.debug && log('start');
    this.setDefaultPointerValue(x, y);
    this.isDown = true;
  }

  update(x, y) {
    this.debug && log('update');
    this.x.current = x;
    this.x.delta = x - this.x.start;
    this.y.current = y;
    this.y.delta = y - this.y.start;
  }

  /**
   * End of the event, reset all values
   */
  end() {
    this.debug && log('end');
    this.setDefaultPointerValue();
    this.isDown = false;
  }

  /**
   * Handler for the `mousedown` event
   * @param Object e The event's object
   */
  mousedownHandler(e) {
    this.debug && log('mousedownHandler');

    // Bind events
    document.addEventListener('mousemove', this, { passive: true });
    document.addEventListener('mouseup', this, { passive: true });

    const x = e.clientX;
    const y = e.clientY;
    this.start(x, y);
  }

  /**
   * Handler for the `mouseup` event
   * @param Object e The event's object
   */
  mouseupHandler(e) {
    this.debug && log('mouseupHandler');
    document.removeEventListener('mouseup', this);
    document.removeEventListener('mousemove', this);
    this.end();
  }

  /**
   * Handler for the `touchmove` event
   * @param Object e The event's object
   */
  mousemoveHandler(e) {
    this.debug && log('mousemoveHandler');
    this.update(e.clientX, e.clientY);
  }

  /**
   * Handler for the `touchstart` event
   * @param Object e The event's object
   */
  touchstartHandler(e) {
    this.debug && log('touchstartHandler');

    document.addEventListener('touchmove', this, { passive: true });
    document.addEventListener('touchend', this, { passive: true });

    const touch = e.touches[0];
    const x = touch.clientX;
    const y = touch.clientY;
    this.start(x, y);
  }

  /**
   * Handler for the `touchend` event
   * @param Object e The event's object
   */
  touchendHandler(e) {
    this.debug && log('touchendHandler');
    document.removeEventListener('touchend', this);
    document.removeEventListener('touchmove', this);
    this.end();
  }

  /**
   * Handler for the `touchmove` event
   * @param Object e The event's object
   */
  touchmoveHandler(e) {
    this.debug && log('touchmoveHandler');
    // Get first touch item
    const touch = e.touches[0];
    this.update(touch.clientX, touch.clientY);
  }
}
