import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Blazy from '../vendors/blazy.min.js';

let timeout = null;

(function (w, d, $, undefined) {
  /* ================================
   * Custom scripts for facets filters
   * ================================ */

  $(document).on('facetwp-refresh', function () {
    // Clear unused timeout
    clearTimeout(timeout);

    // Delay the smooth scroll function
    setTimeout(() => {
      // Perform smooth scroll only if a filter is set
      if (window.location.search) {
        TweenLite.to(w, 0.8, {
          scrollTo: {
            y: $('.main .catalogue').offset().top,
          },
          ease: Expo.easeInOut,
        });
      }
    }, 500);

    // Remove empty result message if present
    $('.catalogue__empty-message').remove();

    // Add pending class
    $('.catalogue').addClass('is-pending');

    // While the end of the pending state is only handled by a
    // facetwp event, we are defining a timeout of 20s to be sure
    // that the products will show up again whenever the event is triggered
    // or not (can prevent some unexpected bugs)
    timeout = setTimeout(() => {
      $('.catalogue').removeClass('is-pending');
    }, 20 * 1000);
  });

  $(document).on('facetwp-loaded', function () {
    console.log('facets reloaded');

    $('.catalogue').removeClass('is-pending');
    $('select').selectric();

    // Init blazy for the collection covers
    const blazyInstance = new Blazy({
      selector: '.js-blazy-collection',
      loadInvisible: true,
      offset: 2000,
    });

    setTimeout(function () {
      blazyInstance.revalidate();
    }, 1000);
  });

  // Handle refresh on click reset button
  $(document).on('click', '.facetwp-type-search .facetwp-btn', function (e) {
    const $this = $(this);
    const $facet = $this.closest('.facetwp-facet');

    if ($this.hasClass('f-reset') || $facet.find('.facetwp-search').val() === '') {
      FWP.refresh();
    }
  });
})(window, document, jQuery);
