import Base from '@studiometa/js-toolkit-legacy/index.umd.js';

export default class RessourceList extends Base.default {
  static config = {
    name: 'RessourceList',
    refs: ['pagination[]'],
  };

  /**
   * on data-ref="pagination[]" click
   */
  onPaginationClick(event, index) {
    const { paged } = this.$refs.pagination[index].dataset;
    this.$emit('change', paged);
  }
}
