/* ================================
 * Utils
 * ================================ */

import $ from 'jquery';
import config from './config.js';

/**
 * Convert a kebab-case string into a camelCase one
 * @param  {string} string The string to convert
 * @returns {string}        The camelCased string
 */
export const camelCase = function (string) {
  return string.toLowerCase().replaceAll(/-(.)/g, (match, group) => {
    return group.toUpperCase();
  });
};

/**
 * Convert a kebab-case string into a PascalCase one
 * @param  {string} string The string to convert
 * @returns {string}        The transformed string
 */
export const pascalCase = function (string) {
  // Remove dashes and transform
  // following letter to uppercase
  string = string.toLowerCase().replaceAll(/-(.)/g, (match, group) => {
    return group.toUpperCase();
  });

  // Return string with first letter
  // transformed to uppercase
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * @param  {number}    wait       Timer
 * @param  {boolean}   immediate  Launch the function immediately
 * @param  {Function}  func       The function that needs debounce
 * @returns {Function}             A function to bind to the event debounced
 */
export const debounce = function (wait, immediate, func) {
  let timeout;

  return function () {
    const context = this;
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

/**
 * Animate scroll for anchor links
 * @returns {void}
 */
// export const smoothScroll = function (event) {
//   if (
//     location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
//     location.hostname == this.hostname
//   ) {
//     const $target = $(this.hash);
//     if ($target.length) {
//       TweenLite.to(window, 0.8, {
//         scrollTo: {
//           y: $target.offset().top,
//         },
//         ease: Expo.easeInOut,
//       });
//       return false;
//     }
//   }
// };

/**
 * Create an ending event for the event triggered
 * @param  {object} e The triggered event's object
 * @returns {undefined}
 */
export const addEndEvent = debounce(200, false, function (e) {
  console.log(`${e.type}End`);
  $(this).trigger(`${e.type}End`);
});

/**
 * Convert degrees to rad
 * @param  {integer} degrees The angle to convert
 * @returns {integer}         The converted angle in rad
 */
export const degToRad = (degrees) => {
  return (degrees * Math.PI) / 180;
};

/**
 * Get a random integer in the given interval
 * @param  {integer} min The interval's minimum
 * @param  {integer} max The interval's maximum
 * @returns {integer}     A random integer in the given interval
 */
export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

/**
 * Define if an element is currently in the viewport
 * @param element
 * @param container
 * @returns {boolean}
 */
export const inViewport = (element, container = window) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top + rect.height >= 0 &&
    rect.left >= 0 &&
    rect.bottom - rect.height <= (window.innerHeight || container.clientHeight) &&
    rect.right <= (window.innerWidth || container.clientWidth)
  );
};

export const injector = function (t, splitter, klass, after) {
  const text = t.text();
  const a = text.split(splitter);
  let inject = '';
  if (a.length) {
    $(a).each(function (i, item) {
      inject += `<span class="${klass}${i + 1}" aria-hidden="true"><span>${
        item == ' ' ? '&nbsp;' : item
      }</span></span>${after}`;
    });
    t.attr('aria-label', text).empty().append(inject);
  }
};

export const splitLineBreak = function (elt) {
  const para = elt;

  config.$window
    .on({
      resize() {
        para.each(function () {
          const current = $(this);
          const text = current.text();
          let newText = '<span><span>';
          const _length = text.length;
          let cursor = 0;

          const words = text.split(' ');
          current.text(words[0]);
          let height = current.height();
          const infoWords = [
            {
              length: words[0].length,
              pos: 0,
            },
          ];

          for (let j = 1; j < words.length; j++) {
            infoWords.push({
              length: words[j].length,
              pos: infoWords[j - 1].pos + infoWords[j - 1].length + 1,
            });
          }
          for (let i = 1; i < words.length; i++) {
            const chars = '';
            current.text(`${current.text()} ${words[i]}`);

            if (current.height() > height) {
              height = current.height();
              const index = text.indexOf(words[i - 1]) + words[i - 1].length;
              newText += `${text.substring(
                infoWords[cursor].pos,
                infoWords[i - 1].pos + infoWords[i - 1].length,
              )}</span></span> <span><span>`;
              cursor = i;
              // for( var j = 0; j < words[i-1].length; j++ ) chars += 'j';
              // text = text.replace(new RegExp(words[i-1]), chars);
              // console.log(words[i-1]);
            }
          }
          newText += `${text.substring(infoWords[cursor].pos, _length)}</span></span>`;
          current.html('').html(newText);
        });
      },
    })
    .trigger('resize');
};
