import Default from './pages/default.js';
import Home from './pages/home.js';
import Ressources from './pages/ressources.js';
import Product from './pages/product.js';

const routes = {
  Default,
  Product,
  Home,
  Ressources,
};

export default routes;
