import Default from './default.js';
import $ from 'jquery';

export default class Product extends Default {
  constructor() {
    super();
  }

  init() {
    super.init();

    // accordion stuff
    const $btn = $('.js-accordion-btn');
    const $list = $('.js-accordion');
    let isOpen = false;

    $btn.on('click', function (e) {
      isOpen = !isOpen;

      if (isOpen) {
        TweenLite.set($list, { height: 'auto' });
        TweenLite.from($list, 1.2, { height: 0, ease: Expo.easeOut });
      } else {
        TweenLite.to($list, 0.6, { height: 0, ease: Expo.easeOut });
      }
    });
  }
}
