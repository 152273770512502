import Base from '@studiometa/js-toolkit-legacy/index.umd.js';

export default class LegacySelect extends Base.default {
  static config = {
    name: 'LegacySelect',
    refs: ['select'],
    options: {
      closeOnSelect: Boolean,
    },
  };

  isOpen = false;

  /**
   * @returns {Array<HTMLOptionElement>}
   */
  get options() {
    return Array.from(this.$refs.select.options);
  }

  /**
   * @returns {number}
   */
  get selectedOptionIndex() {
    return this.options.findIndex((option) => option.selected);
  }

  /**
   * @returns {HTMLOptionElement}
   */
  get selectedOption() {
    return this.options[this.selectedOptionIndex];
  }

  /**
   * @returns {HTMLElement}
   */
  get activeItem() {
    return (
      this.$refs.items.find((item) => item === document.activeElement) ||
      this.$refs.items[this.selectedOptionIndex]
    );
  }

  /**
   * @returns {string}
   */
  get value() {
    return this.selectedOption.value || '';
  }

  /**
   * @param {string} value
   */
  set value(value) {
    const newOption = this.options.find((option) => option.value === value);
    newOption.selected = true;
    this.$refs.select.dispatchEvent(new Event('change'));
    this.$refs.items.forEach((item, index) => {
      if (index === this.selectedOptionIndex) {
        item.classList.add('is-selected');
      } else {
        item.classList.remove('is-selected');
      }
    });
    this.$refs.value.innerHTML = this.label;
  }

  /**
   * @returns {string}
   */
  get label() {
    return this.selectedOption.innerHTML || '';
  }

  /**
   * Set-up attribute and bind events on mount.
   */
  mounted() {
    this.$refs.value.setAttribute('aria-controls', `${this.$id}-list`);
    this.$refs.value.setAttribute('aria-haspopup', 'true');
    this.$refs.value.setAttribute('aria-expanded', 'false');
    this.$refs.list.setAttribute('id', `${this.$id}-list`);
    this.$refs.list.setAttribute('aria-hidden', 'true');
    this.$refs.value.innerHTML = this.label;
    this.$refs.items[this.selectedOptionIndex].classList.add('is-selected');

    document.addEventListener('click', this.onDocumentClick);
  }

  /**
   * Unbind everything on destroy.
   */
  destroyed() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  /**
   * Close on click-outside.
   */
  onDocumentClick(event) {
    if (!this.$refs.container.contains(event.target)) {
      this.close();
    }
  }

  /**
   * Keyboard navigation.
   * @param {KeyServiceProps} props
   */
  keyed({ DOWN, UP, ENTER, ESC, TAB, isDown }) {
    if (isDown) {
      return;
    }

    // Close on escape
    if (this.isOpen && ESC) {
      this.close();
    }

    // Close when focus is outside
    if (this.isOpen && TAB && !this.$refs.list.contains(document.activeElement)) {
      this.close();
    }

    // Set focus to next element on arrow down
    if (this.isOpen && DOWN && this.nextActiveItem) {
      this.nextActiveItem.focus();
    }

    // Set focus to previous element on arrow up
    if (this.isOpen && UP && this.previousActiveItem) {
      this.previousActiveItem.focus();
    }
  }

  /**
   * Toggle on selected value click.
   */
  onValueClick() {
    if (!this.isOpen) {
      this.open();
    } else {
      this.close();
    }
  }

  /**
   * Set value on item click and close if option is activated.
   */
  onItemsClick(event, index) {
    this.value = this.options[index].value;
    if (this.$options.closeOnSelect) {
      this.close();
    }
  }

  /**
   * Emit change when the hidden select is updated.
   */
  onSelectChange(event) {
    this.$emit('change', event);
  }

  /**
   * Open the dropdown.
   */
  open() {
    this.isOpen = true;
    this.$refs.list.style.display = '';
    this.$refs.list.setAttribute('aria-hidden', 'false');
    this.$refs.value.setAttribute('aria-expanded', 'true');
    this.activeItem.focus();
  }

  /**
   * Close the dropdown.
   */
  close() {
    this.isOpen = false;
    this.$refs.list.style.display = 'none';
    this.$refs.list.setAttribute('aria-hidden', 'true');
    this.$refs.value.setAttribute('aria-expanded', 'false');
  }
}
