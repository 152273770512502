import cookies from 'js-cookie';
import ScrollMagic from 'scrollmagic';
import TweenMax from 'gsap';
import Draggable from 'gsap/Draggable';
import $ from 'jquery';
import { addEndEvent, splitLineBreak } from '../utils.js';
import config from '../config.js';
import Lazy from '../utils/lazy.js';
import Drag from '../utils/drag.js';
import Swipe from '../utils/swipe.js';
import accordion from '../utils/accordion.js';

export default class Default {
  /**
   * Constructor function
   * @returns {object} The current instance
   */
  constructor() {
    console.log(`${this.constructor.name}:constructor`);

    const _ = this;

    // ask for cookies
    this.cookies();

    // Split line break in span
    splitLineBreak($('.line-breaker'));

    // Prevent scrollTop on click on a[href="#"] links
    config.$document.on('click', 'a[href="#"]', function (e) {
      e.preventDefault();
    });

    // Add custom classes to language selector
    $('.nav__list:not(.nav__list--wpml) > .menu__item--wpml_ls_menu_item').on(
      'click touch',
      function (e) {
        $(this).toggleClass('is-wpml-ls-open');
      },
    );

    // Create throttled events
    config.$window.on({
      resize: addEndEvent,
      scroll: addEndEvent,
    });

    // Menu stuff
    const $html = $('html');
    $('.header__burger').on('click', function (e) {
      $html.toggleClass('is-open');
      $('.header').toggleClass('is-open');
    });

    if (navigator.userAgent.match(/(Trident|MSIE)/) !== null) {
      $html.toggleClass('is-ie');
    }

    const controller = new ScrollMagic.Controller();
    $('.js-reveal:not(.is-visible)').each(function () {
      const triggerHook =
        $(this).data('trigger-hook') != undefined ? $(this).data('trigger-hook') : 0.9;

      const _scene = new ScrollMagic.Scene({ triggerElement: $(this)[0] })
        .setClassToggle($(this)[0], 'is-visible') // add class toggle
        .triggerHook(triggerHook)
        .addTo(controller);
    });

    _.lazy = new Lazy({
      onSuccess(elt) {
        $(elt).parent().addClass('img-is-loaded');
      },
    });
    _.lazy.init();

    // Check if mac for better font aliasing
    if (config.isMac) config.$html.addClass('is-mac');

    // Autocomplete google map
    // const $autocomplete = $('[data-autocomplete]');
    // if ($autocomplete.length > 0) {
    //   $autocomplete.each(function () {
    //     const $elt = $(this);
    //     const autocomplete = new google.maps.places.Autocomplete($elt[0], {
    //       fields: ['place_id'],
    //     });
    //     autocomplete.addListener('place_changed', function () {
    //       const place = autocomplete.getPlace();

    //       if (place.place_id != null) {
    //         const $placeIdInput = $elt.siblings('[name="map_search_place_id"]');

    //         $placeIdInput.val(place.place_id);
    //         $elt.parents('form').trigger('submit');
    //       }
    //     });
    //   });
    // }

    // Accordion
    const $accordions = $('.faq__item--header');
    if ($accordions.length > 0) {
      $accordions.accordion({
        onStart($this, isOpen) {
          const $elts = $accordions.not($this).removeClass('is-open');
          $elts.data('open', false);
          TweenMax.to($elts.next('.js-accordion__container'), 0.4, {
            height: 0,
            opacity: 0,
            ease: Expo.easeInOut,
          });
        },
      });
    }

    // Sticky elts when is on top of viewport
    config.$window
      .on({
        scroll() {
          const _scrollTop = $(this).scrollTop();

          $('.sticky:not(.bottom-0)').each(function () {
            const $elt = $(this);
            const $parent = $elt.parent();
            const offsetTop = $(this).offset().top;

            if ($elt.data('offsetTop') == null) {
              $elt.data('offsetTop', offsetTop);
            }

            if (_scrollTop >= $elt.data('offsetTop')) {
              $elt.addClass('fixed');

              if ($elt.data('target') != undefined) {
                $elt.find($elt.data('target')).css('margin-left', $elt.data('offsetLeft'));
              } else {
                $elt.css('margin-left', $elt.data('offsetLeft'));
              }

              if (
                _scrollTop + $elt.outerHeight(true) >=
                $parent.offset().top + $parent.outerHeight(true)
              ) {
                $elt.addClass('bottom');
                if ($elt.data('target') != undefined) {
                  $elt.find($elt.data('target')).css('margin-left', 0);
                } else {
                  $elt.css('margin-left', 0);
                }
              } else {
                $elt.removeClass('bottom');
              }
            } else {
              $elt.removeClass('fixed');
              if ($elt.data('target') != undefined) {
                $elt.find($elt.data('target')).css('margin-left', 0);
              } else {
                $elt.css('margin-left', 0);
              }
            }
          });
        },
        resize() {
          $('.sticky').each(function () {
            const $elt = $(this);
            const $parent = $elt.parent();

            $elt.data('offsetLeft', $parent.offset().left);
          });
        },
      })
      .trigger('resize');

    // Init the collaboration drag
    const $refItems = $('.ref-item');
    if ($refItems.length > 0) {
      const timer = 0;

      const drag = new Drag({
        // snap : true,
        btnSelector: '.ref__pointer',
        itemSelector: '.ref-item, .ref__content',
        // onEnd : function() {
        // 	let currentElt = drag.slider.currentElt

        // 	clearTimeout(timer);

        // 	timer = setTimeout(function() {
        // 		$refItems.not(':not(.active)').removeClass('active')
        // 		$refItems.eq(Math.abs(currentElt)).addClass('active')
        // 	}, 200);
        // }
      }).init();
    }

    // Init event click on hotspot
    $('.hotspot').on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      $(this)
        .next('.mapping__popup')
        .addClass('active')
        .one('click', '.trigger-close', function (e) {
          e.preventDefault();
          config.$html.removeClass('show-hotspot');
          $(this).parent().removeClass('active');
          config.$body.off('.click.hotspot');
        });

      config.$body.on('click.hotspot', function (e) {
        if (
          !$(e.target).hasClass('mapping__popup') &&
          $(e.target).parents('.mapping__popup').length <= 0 &&
          !$(e.target).hasClass('wl-list-pop') &&
          $(e.target).parents('.wl-list-pop').length <= 0
        ) {
          e.preventDefault();
          $('.mapping__popup.active').find('.trigger-close').trigger('click');
          $('.wl-list-pop').hide();
        }
      });

      config.$html.addClass('show-hotspot');
    });

    $('.close-modal').on('click', function (e) {
      e.preventDefault();

      $(this).parents('.wl-list-pop').hide();
    });

    return this;
  }

  /**
   * Init the page
   * @returns {object} The current instance
   */
  init() {
    console.log(`${this.constructor.name} is init`);
    const _ = this;

    // Init timeline
    $('.timeline').each(function () {
      _.timeline($(this));
    });

    return this;
  }

  /**
   * Kill the page
   * @returns {object} The current instance
   */
  kill() {
    console.log(`${this.constructor.name} has been killed`);

    return this;
  }

  /**
   * Stuff to do on window resize
   * @param  {integer} width  The current view size
   * @param  {integer} height The current view height
   * @returns {undefined}
   */
  resize(width, height) {
    console.log(`${this.constructor.name} has been resized`);
  }

  /**
   * Handle cookies message
   * @returns {undefined}
   */
  cookies() {
    const $cookies = $('#js-cookies');

    if (cookies.get('terms') === 'accepted') {
      $cookies.remove();
      return;
    }

    $cookies.addClass('is-visible');

    $('#js-cookies__btn').on('click', function () {
      $cookies.remove();
      // Set a cookie that expires in 1 year
      cookies.set('terms', 'accepted', { expires: 365 });
      return false;
    });
  }

  /**
   * Create timeline
   */
  timeline($elt) {
    let current = 0;
    const $items = $elt.find('.timeline-item');
    const $dates = $elt.find('.timeline-item--dates');

    const swipe = new Swipe($elt, function () {
      const { x } = this.pointer;

      if (
        this.direction != 'not moving' &&
        (Math.abs(x.start - x.current) * 100) / config.$window.width() > 0.3
      ) {
        // Do not swipe if the user is scrolling too much vertically.
        if (this.pointer.y.delta > 20 || this.pointer.y.delta < -20) {
          return;
        };

        if (this.direction == 'to the left') {
          var next = current >= $items.length - 1 ? 0 : current + 1;
        } else {
          var next = current <= 0 ? $items.length - 1 : current - 1;
        }

        console.log(next);

        $dates.find('a').eq(next).trigger('click');
      }
    });

    swipe.init();

    $elt.addClass('init-timeline');

    $elt.find('.hentry > *').wrapInner('<div class="elt" />');

    config.$window
      .on('resize', function () {
        TweenMax.set($elt, { height: $items.eq(current).outerHeight(true) });
      })
      .trigger('resize');

    $dates.on('click', 'a', function (e) {
      e.preventDefault();

      if ($(this).hasClass('active')) return;

      // Supprime l'ancien item
      $items.eq(current).removeClass('active');

      current = $(this).index();
      $dates.find('.active').removeClass('active').end();
      $(this).addClass('active');

      // Active the next date
      $items.eq(current).addClass('active');

      // Update position dates
      TweenMax.to($dates, 0.6, { x: $(this).outerWidth(true) * -current, ease: Expo.easeInOut });

      // Update width of current item
      config.$window.trigger('resize');
    });
  }
}
