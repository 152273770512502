const log = window.console.log.bind(window, 'Swipe:');

export default class Swipe {
  constructor($elt, onEnd = function () {}) {
    log('constructor');
    this.$doc = $elt;
    this.$pre = $('pre');
    this.pointer = {
      x: {
        start: null,
        current: null,
        delta: 0,
      },
      y: {
        start: null,
        current: null,
        delta: 0,
      },
    };
    this.isDown = false;
    this.direction = 'not moving';

    this.onEnd = onEnd;
  }

  init() {
    log('init');
    this.$doc.on('touchstart', this.touchstartHandler.bind(this));
    this.$doc.on('mousedown', this.mousedownHandler.bind(this));
    // this.print(this)
  }

  start(x, y) {
    log('start');
    this.pointer.x.start = x;
    this.pointer.x.current = x;
    this.pointer.y.start = y;
    this.pointer.y.current = y;
    this.isDown = true;
    this.tick();
  }

  end() {
    log('end');
    this.isDown = false;
    // this.pointer = {
    // 	x: {
    // 		start: null,
    // 		current: null,
    // 		delta: 0
    // 	},
    // 	y: {
    // 		start: null,
    // 		current: null,
    // 		delta: 0
    // 	}
    // }
    // this.direction = 'not moving'
    this.print(this);

    this.onEnd(this);
  }

  tick() {
    if (this.isDown) {
      log('tick');
      this.pointer.x.delta = this.pointer.x.current - this.pointer.x.start;
      this.pointer.y.delta = this.pointer.y.current - this.pointer.y.start;
      // this.print(this)

      // DO STUFF HERE
      if (this.pointer.x.delta > 0) {
        this.direction = 'to the right';
      } else if (this.pointer.x.delta < 0) {
        this.direction = 'to the left';
      }

      requestAnimationFrame(this.tick.bind(this));
    }
  }

  mousedownHandler(e) {
    log('mousedownHandler');

    // Bind events
    this.$doc.on('mousemove', this.mousemoveHandler.bind(this));
    this.$doc.one('mouseup', (e) => {
      this.$doc.off('mousemove', this.mousemoveHandler);
      this.end();
    });

    const x = e.clientX;
    const y = e.clientY;
    this.start(x, y);
  }

  mousemoveHandler(e) {
    log('mousemoveHandler');
    this.pointer.x.current = e.clientX;
    this.pointer.y.current = e.clientY;
  }

  touchstartHandler(e) {
    log('touchstartHandler');
    this.$doc.on('touchmove', this.touchmoveHandler.bind(this));
    this.$doc.one('touchend', (e) => {
      this.$doc.off('touchmove', this.touchemoveHandler);
      this.end();
    });

    const touch = e.touches[0];
    const x = touch.clientX;
    const y = touch.clientY;
    this.start(x, y);
  }

  touchmoveHandler(e) {
    log('touchmoveHandler');
    // Get first touch item
    const touch = e.touches[0];
    this.pointer.x.current = touch.clientX;
    this.pointer.y.current = touch.clientY;
  }

  print(obj) {
    const string = JSON.stringify(obj, this.filter, 2).replaceAll('"', '');
    this.$pre.html(string);
  }

  filter(key, value) {
    return key !== '$doc' && key !== '$pre' ? value : undefined;
  }
}
