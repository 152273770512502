/* ================================
 * App
 * ================================ */

import {} from './vendors/console.js';
import './utils/facets.js';
import Blazy from './vendors/blazy.min.js';

import config from './config.js';
import { pascalCase, addEndEvent } from './utils.js';
import Pages from './routes.js';

/**
 * Global init function
 * @param  {string} page segment_1 of the URL
 */
class App {
  constructor(page) {
    console.log('App:constructor');
    const _ = this;

    // Init page
    page = page || 'Default';
    page = page.length ? pascalCase(page) : 'Default';
    this.page = Pages.hasOwnProperty(page) ? new Pages[page]() : new Pages.Default();
    this.page.init();

    // Bind resize
    config.$window.on({
      // Debounce the resize event
      resize: addEndEvent,
      resizeEnd: (e) => {
        // Trigger page resize on window resizeEnd
        _.page.resize(window.innerWidth, window.innerHeight);
      },
    });

    /**
     * Init blazy for heros
     */
    new Blazy({
      selector: '.js-blazy',
      breakpoints: [
        {
          width: 480,
          src: 'data-src-mobile',
        },
        {
          width: 768,
          src: 'data-src-tablet',
        },
      ],
    });
  }
}

window.App = App;
